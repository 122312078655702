<template>
  <section class="admin-content">
    <quick_links v-if="is_subscription_payed" />

    <div v-if="show_admin_default_page && is_subscription_payed" class="admin-content__default-page">
      <section class="admin-content__financials">
        <h3 class="md-title">
          {{ $translate("financials") }}:
          
          <md-button class="md-icon-button refresh-admin-data" @click="fetch_financials">
            <md-icon>cached</md-icon>
            <md-tooltip md-direction="top">{{ $translate("refresh") }}</md-tooltip>
          </md-button>
        </h3>

        <div class="md-card admin-content__financial-summary">
          <div class="admin-content__financial-summary-info">
            <Loader
              v-if="!base_financials"
              :size="loader_sizes.small"
            />

            <span v-else class="admin-content__financial-summary-value">{{ base_financials.total_orders }}</span>
            <div class="admin-content__financial-summary-text">
              <p>{{ $translate("orders.total_orders") }}</p>
              {{ $translate("orders.orders_since_beginning") }}
            </div>
          </div>

          <div class="admin-content__financial-summary-info">
            <Loader
              v-if="!base_financials"
              :size="loader_sizes.small"
            />
            <span v-else class="admin-content__financial-summary-value">{{ base_financials.total_paid_products }}</span>
            <div class="admin-content__financial-summary-text">
              <p>{{ $translate("products.total_sold") }}</p>
              {{ $translate("products.total_sold_message") }}
            </div>
          </div>

          <div class="admin-content__financial-summary-info">
            <Loader
              v-if="!base_financials"
              :size="loader_sizes.small"
            />
            <span v-else class="admin-content__financial-summary-value">
              <span v-if="is_currency_symbol(currency_signs[user_currency] || user_currency)">
                {{ currency_signs[user_currency] || user_currency }}
              </span>
              {{ base_financials.total_revenue }}
              <span v-if="!is_currency_symbol(currency_signs[user_currency] || user_currency)">
                {{ currency_signs[user_currency] || user_currency }}
              </span>
            </span>
            <div class="admin-content__financial-summary-text">
              <p>{{ $translate("products.total_revenue") }}</p>
              {{ $translate("products.total_revenue_message") }}
            </div>
          </div>
        </div>

        <md-table v-model="latest_user_orders" md-card class="admin-content__orders-wrapper">
          <md-table-toolbar class="admin-content__orders">
            <h3 class="md-title">
              {{ $translate("orders.latest_orders") }}
              <span>{{ $translate("updated_to") }}: {{ current_datetime }}</span>
            </h3>
            <md-button
              class="md-raised md-primary"
              :class="{
                'selected-button': selected_orders_period === 24,
                'md-icon-button': is_mobile_resolution,
              }"
              @click="() => {selected_orders_period = 24; get_latest_orders(24)}"
            >24h</md-button>
            <md-button
              class="md-raised md-primary"
              :class="{
                'selected-button': selected_orders_period === 48,
                'md-icon-button': is_mobile_resolution,
              }"
              @click="() => {selected_orders_period = 48; get_latest_orders(48)}"
            >48h</md-button>
            <md-button
              class="md-raised md-primary"
              :class="{
                'selected-button': selected_orders_period === 168,
                'md-icon-button': is_mobile_resolution,
              }"
              @click="() => {selected_orders_period = 168; get_latest_orders(168)}"
            >7d</md-button>
          </md-table-toolbar>

          <md-table-row>
            <Loader
              v-if="!latest_orders"
              :size="loader_sizes.small"
            />

            <div v-else-if="latest_orders && latest_orders.length">
              <md-table-head
                v-for="key in table_keys"
                :key="`order-table-key-${key}`"
              >{{ $translate(`orders.table.${key}`) }}</md-table-head>
            </div>
            <h3 v-else>{{ $translate("orders.no_new_orders") }}</h3>
          </md-table-row>

          <md-table-row
            v-if="latest_orders"
            slot="md-table-row"
            slot-scope="{ item }"
            class="orders__list-item"
            @click="() => $router.push(`/admin/finance/orders?opened_orders=${item.id}`)"
          >
            <md-table-cell
              v-for="key in table_keys"
              :key="`order-table-key-${key}`"
              :md-label="$translate(`orders.table.${key}`)"
              :md-sort-by="key"
            >
              <span
                v-if="key === 'financial_status'"
                class="orders__financial-status"
                :class="{
                  'orders__financial-status--green': item[key] === 'paid',
                  'orders__financial-status--orange': item[key] === 'pending'
                }"
              >{{ item[key] }}</span>
              <span
                v-else-if="key === 'status'"
                class="orders__status"
              >{{ item[key] }}</span>
              <span v-else>{{ get_correct_value(item, item[key]) }}</span>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </section>
      
      <section class="admin-content__connections">
        <h3 class="md-title">{{ $translate("connection_checks") }}:</h3>
        <div class="admin-content__connection md-card">
          <paypal_icon />
          <Loader
            v-if="checking_connections"
            :size="loader_sizes.small"
          />

          <div
            v-else
            class="admin-content__connection-status-wrapper"
            :class="{ 'admin-content__connection-status-wrapper--connected': is_paypal_connected }"
          >
            {{ is_paypal_connected ? $translate("connected") : $translate("not_connected") }}
            <span
              class="admin-content__connection-status"
              :class="{ 'admin-content__connection-status--connected': is_paypal_connected }"
            />
          </div>
        </div>

        <div class="admin-content__connection md-card">
          <shopify_icon />
          <Loader
            v-if="checking_connections"
            :size="loader_sizes.small"
          />

          <div
            v-else
            class="admin-content__connection-status-wrapper"
            :class="{ 'admin-content__connection-status-wrapper--connected': is_shopify_connected }"
          >
            {{ is_shopify_connected ? $translate("connected") : $translate("not_connected") }}
            <span
              class="admin-content__connection-status"
              :class="{ 'admin-content__connection-status--connected': is_shopify_connected }"
            />
          </div>
        </div>
      </section>

      <section class="admin-content__financial-analysis-button">
        <md-button
          class="md-primary"
          @click="$router.push(`/Admin/finance/${analytics}`)"
        >
          {{ $translate("financial_analytics_link") }}
        </md-button>
      </section>
    </div>

    <router-view v-else name="admin_content"/>

    <md-snackbar
      v-if="global_error"
      md-position="center"
      :md-active="!!global_error"
      md-persistent
      :md-duration="global_error.duration || message_durations.medium"
      :style="{ fontWeight: '600' }"
      :class="{
        'md-success': global_error.severity === severities.SUCCESS,
        'md-warning': global_error.severity === severities.WARNING,
        'md-alert': global_error.severity === severities.DANGER || !global_error.severity
      }"
    >
      <div
        v-html="$translation_exists(`api_messages.${global_error.message}`) ?
          $translate(`api_messages.${global_error.message}`) : global_error.message
        "
      />

      <md-button
        v-if="global_error.button"
        class="md-raised md-primary admin-content__snackbar-button"
        @click="() => {
          global_error.button.function();
          delete_global_error()
        }"
      >
        {{ global_error.button.text }}
      </md-button>
      <md-button class="md-icon-button" @click="delete_global_error">
        <md-icon>close</md-icon>
        <md-tooltip md-direction="top">{{ $translate("admin_content.dismiss") }}</md-tooltip>
      </md-button>
    </md-snackbar>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex"
import Loader from "@/applications/Shared/components/loader/index.vue"
import {
  ADMIN, FINANCE_STORE, CONFIGURATION_STORE, severities, USER_STORE, analytics
} from "../../constants/others_constants"
import {
  ADD_GLOBAL_ERROR, DELETE_GLOBAL_ERROR, FETCH_IMPORTED_FINANCIALS, FETCH_SHOPIFY_FINANCIALS,
  UPDATE_LOADER, FETCH_AND_ASSING_MOMENT_LIBRARY
} from "../../stores/Admin/constants"
import {
  message_durations, date_time_format,
  currency_signs, SHARED_STORE
} from "../../../Shared/constants/other"
import paypal_icon from "../../../Shared/components/icon_components/paypal-icon"
import shopify_icon from "../../../Shared/components/icon_components/shopify-icon"
import paypal_connection_check from "../../methods/paypal_connection_check"
import shopify_connection_check from "../../methods/shopify_connection_check"
import is_currency_symbol from "../../../Shared/methods/is_currency_symbol"
import { GET_USER_CURRENCY } from "../../stores/Admin/user/constants"
import { GET_BASIC_FINANCIAL_OVERVIEW, GET_LATEST_ORDERS } from "../../stores/Admin/finance/constants"
import { loader_sizes } from "../../../Shared/components/loader"
import quick_links from "../utils/quick_links"

export default {
  components: {
    shopify_icon,
    paypal_icon,
    Loader,
    quick_links
  },
  data() {
    return {
      show_admin_default_page: false,
      is_paypal_connected: false,
      is_shopify_connected: false,
      checking_connections: false,
      quick_links_hidden: false,
      table_keys: [
        "order_number",
        "status",
        "financial_status",
        "subtotal_price",
        "total_price",
        "currency",
        "created_at",
      ],
      current_datetime: null,
      selected_orders_period: 24,
      message_durations,
      currency_signs,
      severities,
      analytics,
      loader_sizes,
    }
  },
  computed: {
    ...mapState(ADMIN, ["global_error", "$moment", "subscription_payed", "feature_toggles"]),
    ...mapState(FINANCE_STORE, [
      "base_financials",
      "latest_orders"
    ]),
    ...mapGetters(USER_STORE, {
      user_currency: GET_USER_CURRENCY
    }),
    ...mapState(CONFIGURATION_STORE, ["shopify_config", "paypal_config"]),
    ...mapState(USER_STORE, ["user_info", "rates_info"]),
    ...mapState(SHARED_STORE, ["is_mobile_resolution"]),
    latest_user_orders() {
      return this.latest_orders || []
    },
    is_subscription_payed() {
      return this.feature_toggles.billing ? this.subscription_payed : true
    },
  },
  watch: {
    global_error(error) {
      if (error) setTimeout(this.delete_global_error, error.duration || message_durations.medium)
    },
    '$route.fullPath'() { this.initial_load_checks() }
  },
  async mounted() {
    await this.fetch_and_assing_moment()

    this.current_datetime = this.$moment().format(date_time_format)
    this.initial_load_checks()
  },
  beforeDestroy() {
    this.selected_orders_period = 24
  },
  methods: {
    ...mapMutations(ADMIN, {
      delete_global_error: DELETE_GLOBAL_ERROR,
      add_global_error: ADD_GLOBAL_ERROR,
      update_loader: UPDATE_LOADER
    }),
    ...mapActions(ADMIN, {
      fetch_and_assing_moment: FETCH_AND_ASSING_MOMENT_LIBRARY,
      fetch_shopify_financials: FETCH_SHOPIFY_FINANCIALS,
      fetch_imported_financials: FETCH_IMPORTED_FINANCIALS
    }),
    ...mapActions(FINANCE_STORE, {
      fetch_basic_financials: GET_BASIC_FINANCIAL_OVERVIEW,
      get_latest_orders: GET_LATEST_ORDERS
    }),
    is_currency_symbol,
    async initial_load_checks() {
      const possible_routes = [
        `/${ADMIN}`.toLowerCase(),
        "/",
        `/${ADMIN}/${this.$route.params.store_id}`.toLowerCase(),
        `/${this.$route.params.store_id}`
      ]
      this.show_admin_default_page = possible_routes.includes(this.$route.path.toLowerCase())

      this.update_loader(true)
      if (this.show_admin_default_page) {
        await this.fetch_financials()
        this.check_connections()
      }
      this.update_loader(false)
    },
    get_formated_date(unformatted_date) {
      return this.$moment(unformatted_date, "YYYY-MM-DDTHH:mm:ss").format(date_time_format)
    },
    get_correct_value(order, val) {
      if (val === null) return "undefined"
      if (!isNaN(val)) return Number(val)
      if (this.$moment(val).isValid()) return this.get_formated_date(val)

      return val
    },
    async check_connections() {
      this.checking_connections = true

      if (this.shopify_config.shopify_public) {
        const { is_shopify_configured_correctly } = await shopify_connection_check(this.shopify_config)
        this.is_shopify_connected = is_shopify_configured_correctly
      }
      if (this.paypal_config && this.paypal_config.client_id) {
        this.is_paypal_connected = await paypal_connection_check(this.paypal_config.client_id)
      }

      this.checking_connections = false
    },
    async fetch_financials() {
      await Promise.all([
        this.fetch_basic_financials(),
        this.get_latest_orders()
      ])
    },
  }
}
</script>

<style lang="scss">
  @use "../../../../styles/_global-constants" as *;
  @use "../../../../styles/_admin-constants" as *;
  @use "../../../../styles/selected_button_styling.scss";

  .admin-content {
    position: relative;
    min-height: calc(100vh - $admin-top-bar-height);

    &__orders {
      @media (max-width: $material-medium-breakpoint) {
        display: block;
      }

      &-wrapper {
        @media (max-width: $tablet) {
          margin: $half-default-size 0 !important;
        }

        .md-table-row {
          h3 {
            padding: $material-size;
            margin: 0 0 0 8px;
            color: $material-grey--darker;
            text-align: center;
          }

         .loader-wave {
            margin: $default-size auto;
            justify-content: center;
          }
        }
      }

      .md-title {
        @media (max-width: $material-medium-breakpoint) {
          font-size: $font-size--medium;
        }

        @media (max-width: $mobile) {
          margin-bottom: $half-default-size;
        }

        span {
          margin-left: $half-default-size;
          font-size: $font-size--small;

          @media (max-width: $material-medium-breakpoint) {
            display: block;
            margin-left: 0 !important;
            font-size: $font-size--tiny;
            line-height: normal;
          }
        }
      }
    }

    &__type-chooser {
      display: inline-block;
    }

    &__financials {
      svg {
        width: 100%;
        height: 100%;
      }

      .md-button-content {
        display: flex;
        align-items: center;
      }

      .currency_selector {
        float: right;
        line-height: 42px;
        margin-right: $default-size;

        &__options {
          right: -14px;
          text-align: center;
        }
      }

      .admin-content__type-chooser {
        vertical-align: middle;
      }
    }

    &__type-icon {
      display: inline-block;
      border-radius: 50%;
      border: 1px solid $material-grey;

      &--selected {
        border-color: $blue;
      }
    }

    &__financial-analysis-button {
      margin: 100px 0 $default-size;
      text-align: center;
      font-weight: bold;
      font-size: $font-size--large;
      color: $material-grey--darkest;

      @media (max-width: $tablet) {
        margin-top: 50px;
      }

      .md-button {
        margin: 0 auto;
      }
    }

    &__financial {
      &-summary {
        margin-bottom: $default-size;
        padding: $half-default-size $default-size;

        @media (max-width: $medium-desktop) {
          padding: $default-size;
        }

        @media (max-width: $tablet) {
          padding: $half-default-size;
          margin: $default-size 0 !important;
        }

       .loader-wave {
          margin: 0 0 $half-default-size;

          @media (max-width: $tablet) {
            justify-content: end;
            padding-right: $default-size;
            margin: 0;
          }

          @media (max-width: $mobile--large) {
            margin: $half-default-size 0 0;
            justify-content: start;
            padding-right: 0;
          }
        }

        &-info {
          display: inline-block;
          width: 33%;
          padding: 0 $half-default-size;
          vertical-align: middle;
          font-size: 40px;
          font-weight: bold;
          box-sizing: border-box;

          @media (max-width: $small-desktop) {
            width: 100%;
          }

          @media (max-width: $smaller-desktop) {
            width: calc(50% - 2px);
            margin-bottom: $double-default-size;
            padding: 0;

            &:last-child {
              color: $orange;
              margin-bottom: 0;
            }
          }

          @media (max-width: $tablet) {
            display: grid;
            width: 100%;
            grid-template-columns: 1fr 1fr;
            margin-bottom: $default-size;
            grid-template-areas: "value text";
          }

          @media (max-width: $mobile--large) {
            grid-template-areas: "text" "value";
            margin-bottom: $half-default-size;
            grid-template-columns: 1fr;
          }

          &:first-child {
            padding-left: 0;
            color: $orange;
          }
          &:nth-child(2) {
            color: $blue;
          }
          &:last-child {
            padding-right: 0;
            color: $green;
          }
        }

        &-value {
          grid-area: value;
          line-height: $double-default-size;

          @media (max-width: $material-medium-breakpoint) {
            font-size: $title-size--very-large;
            margin-right: $half-default-size;
          }

          @media (max-width: $tablet) {
            text-align: right;
          }

          @media (max-width: $tablet--small) {
            font-size: $title-size--medium;
          }

          @media (max-width: $mobile--large) {
            text-align: left;
          }
        }

        &-text {
          display: inline-block;
          margin-left: 5px;
          line-height: 18px;
          grid-area: text;

          @media (max-width: $material-medium-breakpoint) {
            margin: 0
          }

          p {
            margin: 0;
            font-size: 17px;
            color: $material-grey--darkest;
          }

          font-size: 12px;
          color: $material-grey--darker;
        }
      }
    }

    &__connection {
      display: inline-block;
      padding: 15px $default-size;
      margin-right: $default-size;
      box-sizing: border-box;

      &s {
        margin-top: $default-size;
      }

      svg {
        display: inline-block;
        width: $double-default-size;
        height: $double-default-size;
        margin-right: $default-size;
        vertical-align: middle;
      }

     .loader-wave {
        display: inline-flex;
        vertical-align: middle;
      }

      &-status {
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-left: $half-default-size;
        background: $red-vivid;
        border-radius: 100%;
        vertical-align: text-top;

        &--connected {
          background: $green-vivid;
        }

        &-wrapper {
          display: inline-block;
          vertical-align: middle;
          color: $red-vivid;

          &--connected {
            color: $green-vivid;
          }
        }
      }
    }

    .md-table-toolbar {
      .md-button {
        margin-left: $half-default-size;
        margin-right: 0;
      }
    }
  }
  
  .orders__list-item {
    position: relative;
    cursor: pointer;
  }

  .orders__financial-status {
    padding: 5px $half-default-size;
    background-color: $material-red;
    color: $pure-white;
    font-weight: bold;
    text-transform: uppercase;

    &--green {
      background-color: $green;
    }
    &--orange {
      background-color: $orange;
    }
  }

  .md-button.md-raised.admin-content__snackbar-button {
    margin-left: $half-default-size !important;
  }
</style>
