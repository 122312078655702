import store from "../../../store";
import { LOG_OUT_USER } from "../../Shared/stores/Shared/constants";
import { SHARED_STORE, CONFIGURATION, ADMIN } from "../constants/others_constants";

export default async () => {
  if (store?.state?.[ADMIN][CONFIGURATION]?.project_styles) {
    Object.keys(store?.state?.[ADMIN][CONFIGURATION]?.project_styles).forEach((key) =>
      document.documentElement.style.setProperty(`--${key}`, "")
    );
  }
  const is_global_admin = store.state[SHARED_STORE].is_global_admin;

  await store.dispatch(`${SHARED_STORE}/${LOG_OUT_USER}`, {
    route: is_global_admin ? "/" : "/admin",
  });
};
