import store from "../../../store"
import { ADMIN_STORE } from "../constants/others_constants";
import { LOGIN_VIA_TOKEN } from "../stores/Admin/constants";
import init_user_logout from "./init_user_logout";

export default async () => {
  try {
    if (!window.firebase_storage) {
      await store.dispatch(`${ADMIN_STORE}/${LOGIN_VIA_TOKEN}`);
      window.firebase_storage = window.firebase.storage(); // TODO get rid of once image upload on BE
    }
  } catch (error) {
    console.log(error)
    await init_user_logout()
  }
}
