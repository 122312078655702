<template>
  <section class="admin" id="admin">
    <div v-if="data_loaded">
      <md-app
        :class="{
          'admin--side-menu-open': open_menu && is_subscription_payed,
          'admin--subscription-unpaid': !is_subscription_payed
        }"
      >
        <md-app-toolbar class="admin__toolbar md-elevation-3" md-elevation="0">
          <md-button v-if="!open_menu && is_subscription_payed" class="md-icon-button" @click="toggle_menu(!open_menu)">
            <md-icon>arrow_forward_ios</md-icon>
          </md-button>
          <div v-else />

          <a :href="correct_store_domain" target="_blank" class="admin__store-link">
            <img v-if="store_icon" :src="store_icon" alt="Store icon">
            <span class="md-title" @click="$router.push(`/admin`)">
              <b>{{ project_name || (project_config ? (project_config.domain || "").toUpperCase() : "") }}</b> {{ $translate("title") }}
            </span>
          </a>

          <div class="admin__toolbar-functions md-toolbar-section-end">
            <div
              v-if="show_countdown_clock"
              class="admin__countdown"
              @click="$router.push('/admin/user/billing')"
            >
              <p v-if="!is_subscription_payed && countdown_finished">
                <md-tooltip md-direction="left">{{ $translate("countdown_finished_message") }}</md-tooltip>
                {{ $translate("countdown_finished_title") }}
              </p>
              <div v-else>
                <md-tooltip class="admin__countdown-tooltip" md-direction="left">{{ $translate("countdown_clock_title") }}</md-tooltip>
                <div class="admin__countdown-clock">
                  <span class="admin__countdown-clock-time-wrapper">
                    <span class="admin__countdown-clock-time">{{ $translate("time.days") }}</span>
                    <b :class="{ 'admin__countdown--warning': final_countdown_hour }">{{ end_days }}</b>
                  </span>
                  <span class="admin__countdown-clock-time-wrapper">
                    <span class="admin__countdown-clock-time">{{ $translate("time.hours") }}</span>
                    <b :class="{ 'admin__countdown--warning': final_countdown_hour }">{{ end_hours }}</b>
                  </span>
                  <span class="admin__countdown-clock-time-wrapper">
                    <span class="admin__countdown-clock-time">{{ $translate("time.minutes") }}</span>
                    <b :class="{ 'admin__countdown--warning': final_countdown_hour }">{{ end_minutes }}</b>
                  </span>
                </div>
              </div>
            </div>
            <div v-else />

            <div class="admin__toolbar-buttons">
              <md-button
                v-show="show_countdown_clock"
                class="admin__countdown-button md-icon-button md-accent"
              >
                <md-icon>timer</md-icon>
                <md-tooltip md-direction="bottom">
                  <span v-if="!is_subscription_payed && countdown_finished">
                    {{ $translate("countdown_finished_message") }}
                  </span>
                  <span v-else>
                    {{ $translate("countdown_clock_title") }}: {{ end_days }}D {{ end_hours }}h {{ end_minutes }}m
                  </span>
                </md-tooltip>
              </md-button>

              <md-button
                class="admin__home md-icon-button"
                @click="$router.push(`/admin`)"
                data-cy="home"
              >
                <md-icon>home</md-icon>
              </md-button>

              <md-button
                class="admin__notifications md-icon-button"
                @click="toggle_notifications(false)"
              >
                <md-tooltip md-direction="left">{{ $translate("notifications.title") }}</md-tooltip>
                <md-icon>notifications</md-icon>
                <span class="admin__notifications-count">{{ Object.keys(notifications).length }}</span>
              </md-button>

              <md-button
                v-if="is_subscription_payed"
                id="store-preview-toggle"
                class="admin__store-preview-button md-icon-button"
                @click="toggle_store_preview"
              >
                <md-tooltip md-direction="left">{{ $translate(show_store_preview ? "close_preview_store" : "preview_store") }}</md-tooltip>
                <md-icon>{{ show_store_preview ? "close" : "preview" }}</md-icon>
              </md-button>

              <md-button @click="open_user_menu = !open_user_menu" class="md-icon-button">
                <md-tooltip md-direction="left">{{ $translate("user.user_actions") }}</md-tooltip>
                <md-icon>account_box</md-icon>
              </md-button>
            </div>
          </div>
        </md-app-toolbar>
        <md-app-drawer v-if="is_subscription_payed" :md-active.sync="toggle_side_menu" md-persistent="mini" class="admin__side-menu">
          <side_menu />
        </md-app-drawer>
        <md-app-content class="admin__content">
          <error_bar :error_list="error_list"/>
          <md-drawer class="md-right admin__user-menu" :md-active.sync="open_user_menu">
            <md-toolbar class="md-transparent" md-elevation="3">
              <p class="md-list-item-text admin__user-message">{{ $translate("user.messages.user_welcome", { name: user_info.name }) }}</p>
            </md-toolbar>

            <md-list>
              <md-list-item v-if="is_subscription_payed" @click="() => { $router.push('/admin/user/profile'); open_user_menu = false }">
                <span class="md-list-item-text">{{ $translate("user.profile") }}</span>
                <md-icon>perm_identity</md-icon>
              </md-list-item>

              <md-list-item
                v-if="is_subscription_payed && permissions.user.sections.permissions.module"
                @click="() => { $router.push('/admin/user/user_management'); open_user_menu = false }"
              >
                <span class="md-list-item-text">{{ $translate("user.manage_users") }}</span>
                <md-icon>supervisor_account</md-icon>
              </md-list-item>

              <md-list-item
                v-if="is_subscription_payed && permissions.user.sections.settings.module"
                @click="() => { $router.push('/admin/user/settings'); open_user_menu = false }"
              >
                <span class="md-list-item-text">{{ $translate("user.settings") }}</span>
                <md-icon>settings</md-icon>
              </md-list-item>

              <md-list-item
                v-if="permissions.user.sections.billing && permissions.user.sections.billing.module && feature_toggles.billing"
                @click="() => { $router.push(`/billing/${project_config.project_id}`); open_user_menu = false }"
              >
                <span class="md-list-item-text">{{ $translate("user.billing.title") }}</span>
                <md-icon>payments</md-icon>
              </md-list-item>

              <md-list-item v-if="is_global_admin" @click="switch_selected_project">
                <span class="md-list-item-text">{{ $translate("user.switch_project") }}</span>
                <md-icon>swap_horiz</md-icon>
              </md-list-item>

              <md-list-item @click="() => { $router.push('/admin/user/support'); open_user_menu = false }">
                <span class="md-list-item-text">{{ $translate("user.support") }}</span>
                <md-icon>support_agent</md-icon>
              </md-list-item>

              <md-list-item v-if="is_subscription_payed" @click="show_guide">
                <span class="md-list-item-text">{{ $translate("user.show_guide") }}</span>
                <md-icon>slideshow</md-icon>
              </md-list-item>

              <md-list-item @click="init_user_logout">
                <span class="md-list-item-text">{{ $translate("user.log_out") }}</span>
                <md-icon>login</md-icon>
              </md-list-item>
            </md-list>

            <md-button
              v-if="permissions.admin"
              class="admin__store-settings md-icon-button"
              @click="() => { $router.push('/admin/user/store_settings'); open_user_menu = false }"
            >
              <md-tooltip md-direction="left">{{ $translate("user.store_settings.title") }}</md-tooltip>
              <md-icon>settings</md-icon>
            </md-button>
          </md-drawer>

          <transition name="store-preview">
            <div v-if="show_store_preview">
              <p v-if="any_unsaved_changes" class="admin__preview-changes-message">{{ $translate("unsaved_change_store_preview") }}</p>
              <iFrame id="store-preview-iframe">
                <full_store_preview/>
              </iFrame>
            </div>
          </transition>

          <transition name="store-preview">
            <admin_content v-if="!show_store_preview"/>
          </transition>

          <transition name="notification-slide">
            <notifications v-if="show_notifications"/>
          </transition>
        </md-app-content>
      </md-app>

      <Loader
        v-if="loading_small"
        class="admin-loader--small"
        :size="loader_sizes.small"
      />

      <md-dialog :md-active.sync="show_profile_setup_modal_comp" :md-click-outside-to-close="false" :md-close-on-esc="false">
        <md-dialog-title>{{ $translate("user.finalize_profile_setup") }}</md-dialog-title>

        <md-dialog-content>
          {{ $translate("user.finalize_profile_message") }}
          <br/>
          <router-link to="/admin/user/profile" @click.native="show_profile_setup_modal_comp = false">{{ $translate("here") }}</router-link>
        </md-dialog-content>
      </md-dialog>

      <!-- Currently unused -->
      <!-- <md-dialog :md-active.sync="show_guide_modal" :md-click-outside-to-close="false" :md-close-on-esc="false">
        <md-dialog-content class="admin__guide-modal">
          <md-button class="md-icon-button admin__guide-modal-close" @click="show_guide_modal = false">
            <md-icon>close</md-icon>
          </md-button>

          <h1>
            {{ $translate("guide.title") }}
          </h1>

          <div v-html="$translate('guide.message')" />

          <div class="admin__guide-buttons">
            <md-button
              class="md-raised md-primary"
              @click="init_tour('store')"
            >{{ $translate("guide.full_button") }}</md-button>
            <md-button
              class="md-raised md-primary"
              @click="init_tour('marketplace')"
            >{{ $translate("guide.marketplace_button") }}</md-button>
          </div>
        </md-dialog-content>
      </md-dialog> -->

      <md-dialog :md-active.sync="show_store_tour">
        <md-dialog-content class="admin__store-tour-modal">
          <md-button class="md-icon-button admin__guide-modal-close" @click="show_store_tour = false">
            <md-icon>close</md-icon>
          </md-button>

          <h1 class="md-headline">
            {{ $translate("store_setup.title") }}
          </h1>

          <div lass="md-body-2" v-html="$translate('store_setup.message')" />

          <div class="admin__store-tour-buttons">
            <triple_arrow_button
              :text="$translate('store_setup.continue_button')"
              :small="true"
              @click="start_store_tour"
            />
          </div>
        </md-dialog-content>
      </md-dialog>
    </div>
    <!-- TODO purchase Zendesk plan -->
    <!-- <div ref="zendesk-wrapper"/> -->
  </section>
</template>

<script>
import Vue from "vue"
import Loader from "@/applications/Shared/components/loader/index.vue"
import { mapState, mapMutations, mapActions } from "vuex"
import VueYouTubeEmbed from "vue-youtube-embed"
import mixin from "../methods/mixin"
import side_menu from "./layout/side_menu"
import admin_content from "./layout/admin_content"
import {
  TOGGLE_MENU, ADD_GLOBAL_ERROR, FETCH_FIREBASE_DATA, UPDATE_GUIDE_INFO, LOGIN_VIA_TOKEN, UPDATE_GUIDE_OPEN_STATE,
  FETCH_USER_CURRENCY_RATES, CLEAR_ADMIN_STORES, UPDATE_PROJECT_STATE, UPDATE_SUBSCRIPTION_PAYED, FETCH_SUBSCRIPTION_STATUS
} from "../stores/Admin/constants"
import error_bar from "./utils/error_bar"
import { ADMIN_STORE, CONFIGURATION_STORE, SHOW_QUICK_LINKS, SWITCH_PROJECT, TOGGLE_ELEMENTS, USER_STORE } from "../constants/others_constants"
import { default_language, SHARED_STORE } from "../../Shared/constants/other"
import { SET_USER_SELECTED_PROJECT } from "../stores/Admin/user/constants"
import init_firebase from "../../Shared/methods/init_firebase"
import { ADMIN_LOGIN, LOG_OUT_USER } from "../../Shared/stores/Shared/constants"
import { UPDATE_GLOBAL_LOADER } from "../../../store"
import {
  image_dimension_names,
  update_admin_language,
  user_basket_items,
  user_viewed_products
} from "../../../data/other_constants"
import iFrame from "../../Shared/components/utils/iframe"
import { event_hub } from "../../../main"
import countdown_clock_calculation from "../methods/countdown_clock_calculation"
import notifications from "./utils/notifications"
import { loader_sizes } from "../../Shared/components/loader"
import get_image_src from "../../Shared/methods/get_image_src"
import check_if_user_in_dev_env from "../../Shared/methods/check_if_user_in_dev_env"
import { TEST } from "../../../data/page_types"
import {base_tour_config} from "../constants/user_tours/default_setups"
import { new_user_store_tour } from "../constants/user_tours/initial_tour"
import triple_arrow_button from "./utils/triple_arrow_button"
import auth_and_init_storage from "../methods/auth_and_init_storage"
import init_user_logout from "../methods/init_user_logout"

const full_store_preview = () => import("../components/content/full_store_preview")

// Filters
Vue.filter("capitalize", value => !value ? "" : value.toString().charAt(0).toUpperCase() + value.slice(1))
Vue.mixin(mixin)

// Admin specific install
Vue.use(VueYouTubeEmbed)

export default {
  components: {
    Loader,
    side_menu,
    admin_content,
    error_bar,
    full_store_preview,
    iFrame,
    notifications,
    triple_arrow_button
  },
  props: {
    selected_language: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      open_user_menu: false,
      show_profile_setup_modal: false,
      show_store_tour: false,
      // Currently unused
      // show_guide_modal: false,
      show_store_preview: false,
      preparing_guide: false,
      store_data_loaded: false,
      show_notifications: false,
      newly_created_store: true,
      should_check_subscription: false,
      end_days: "",
      end_hours: "",
      end_minutes: "",
      loader_sizes,
    }
  },
  computed: {
    ...mapState(ADMIN_STORE, [
      "open_menu",
      "data_loaded",
      "error_list",
      "loading_small",
      "guide_open_state",
      "user_edited_shopify_products",
      "user_edited_imported_products",
      "user_edited_website_configuration",
      "user_edited_header_items",
      "user_edited_shipping_options",
      "user_edited_content",
      "user_edited_footer",
      "user_edited_couple_product_homepages",
      "user_edited_single_product_homepages",
      "user_edited_finance_data",
      "user_edited_special_type_homepages",
      "user_edited_social_data",
      "feature_toggles",
      "free_trial_end",
      "notifications",
      "subscription_payed"
    ]),
    ...mapState(SHARED_STORE, ["is_global_admin", "is_mobile_resolution"]),
    ...mapState(CONFIGURATION_STORE, [
      "project_config", "rest_config", "project_name", "project_styles"
    ]),
    ...mapState(USER_STORE, [
      "permissions", "user_info", "projects",  "showing_store_tour", "showing_marketplace_tour", "paying_user", "selected_project"
    ]),
    toggle_side_menu: {
      get() {
        return this.open_menu
      },
      set(value) {
        this.toggle_menu(value)
      }
    },
    selected_store() {
      return this.projects.find(({ project_id }) => project_id === this.selected_project)
    },
    store_icon() {
      return typeof this.selected_store?.icon === "string" ?
          this.selected_store?.icon : get_image_src(this.selected_store?.icon)
    },
    correct_store_domain() {
      return this.selected_store?.domain ?
        `https://${this.selected_store.domain}` :
        `https://${
          check_if_user_in_dev_env() ? `${TEST}-` : ""
        }stores.ulsemo.com/${
          this.selected_store?.project_name_id || this.selected_project || this.project_config.project_id
        }`
    },
    show_profile_setup_modal_comp: {
      get() {
        // Re-enable once we have a better solution for the profile setup
        // return !this.showing_tour && !this.guide_open_state && this.show_profile_setup_modal && !this.preparing_guide
        return false
      },
      set(val) {
        this.show_profile_setup_modal = val
      }
    },
    showing_tour() {
      return this.showing_store_tour || this.showing_marketplace_tour
    },
    any_unsaved_changes() {
      return this.user_edited_header_items ||
        this.user_edited_shipping_options ||
        Object.keys(this.user_edited_content).length ||
        Object.keys(this.user_edited_website_configuration).length ||
        this.user_edited_footer ||
        this.user_edited_couple_product_homepages ||
        this.user_edited_single_product_homepages ||
        this.user_edited_shopify_products ||
        this.user_edited_imported_products ||
        this.user_edited_finance_data ||
        this.user_edited_special_type_homepages ||
        this.user_edited_social_data
    },
    final_countdown_hour() {
      return this.free_trial_end ? Number(this.end_days) === 0 : false
    },
    countdown_finished() {
      return [this.end_days, this.end_hours, this.end_minutes].every(val => Number(val) === 0)
    },
    is_subscription_payed() {
      if (!this.feature_toggles.billing) return true

      return this.free_trial_end ? !this.countdown_finished : this.paying_user
    },
    show_countdown_clock() {
      return this.store_data_loaded && this.feature_toggles.billing && (!this.countdown_finished || !this.is_subscription_payed)
    },
  },
  watch: {
    user_info({ language }, { language: old_language }) {
      if (language !== old_language) this.update_user_language()
    },
    $route() {
      this.should_redirect_to_billing()
    },
    open_user_menu(opening) {
      if (opening) this.toggle_notifications(true)
      if (window.zE) window.zE('messenger:set', 'zIndex', opening ? -1 : 9999);
    },
    show_store_tour(val) {
      if (!val) window.localStorage.setItem("admin_tour_shown", true)
    },
    should_check_subscription(val) {
      if (val) this.fetch_subscription_status()
    },
  },
  async mounted() {
    this.update_loader(true)

    // Re-check subscription if user was redirected from Stripe and created a session
    if (this.$route.query?.stripe_session_created) await this.fetch_subscription_status()

    this.check_subscription_interval = setInterval(() => {
      this.should_check_subscription = true
    }, 2.88e+7) // Check every 8 hours if subscription is payed

    // TODO purchase Zendesk plan
    // this.init_zendesk()

    try {
      await init_firebase(this.$loadScript)

      await auth_and_init_storage()

      await Promise.all([
        await this.fetch_initial_data(false),
        await this.fetch_currency_rates(),
      ])

      this.store_data_loaded = true

      if (!this.user_info.language_code || !this.user_info.name) this.show_profile_setup_modal = true
      if (this.free_trial_end) this.countdown_clock_calculation() // Starts free trial countdown

      this.should_redirect_to_billing()

      // Currently unused
      // this.show_guide_modal = this.project_config.new_project && this.newly_created_store && this.projects.length === 1

      if (this.project_config.new_project) {
        this.newly_created_store = false
        this.update_project_state()
      }

      this.update_loader(false)
      this.update_user_language()
      this.update_favicon()
      this.show_store_tour = Boolean(
        this.$route?.query?.new_store &&
        !window.localStorage.getItem("admin_tour_shown")
      )
    } catch (error) {
      this.add_global_error(error)
    } finally {
      this.update_loader(false)
    }

    event_hub.$on(
      TOGGLE_ELEMENTS,
      () => {
        this.toggle_notifications(true)
        if (this.show_store_preview) this.toggle_store_preview()
      }
    )
    event_hub.$on(SWITCH_PROJECT, this.switch_selected_project)
  },
  beforeDestroy() {
    this.check_subscription_interval && clearInterval(this.check_subscription_interval)
    event_hub.$off(TOGGLE_ELEMENTS)
    event_hub.$off(SWITCH_PROJECT)
  },
  methods: {
    ...mapMutations(USER_STORE, {
      select_user_project: SET_USER_SELECTED_PROJECT,
    }),
    ...mapMutations(ADMIN_STORE, {
      toggle_menu: TOGGLE_MENU,
      add_global_error: ADD_GLOBAL_ERROR,
      update_guide: UPDATE_GUIDE_INFO,
      toggle_guide_state: UPDATE_GUIDE_OPEN_STATE,
      update_subscription_payed: UPDATE_SUBSCRIPTION_PAYED
    }),
    ...mapActions(ADMIN_STORE, {
      fetch_initial_data: FETCH_FIREBASE_DATA,
      login_user_with_token: LOGIN_VIA_TOKEN,
      fetch_currency_rates: FETCH_USER_CURRENCY_RATES,
      clear_stores: CLEAR_ADMIN_STORES,
      update_project_state: UPDATE_PROJECT_STATE,
      fetch_subscription_status: FETCH_SUBSCRIPTION_STATUS,
    }),
    ...mapActions(SHARED_STORE, {
      log_out_user: LOG_OUT_USER,
      admin_login: ADMIN_LOGIN
    }),
    ...mapMutations({
      update_loader: UPDATE_GLOBAL_LOADER
    }),
    init_user_logout,
    countdown_clock_calculation,
    async start_store_tour() {
      await Promise.all([
        import("../methods/user_tour"),
        import("../../../styles/user_tour.css")
      ])

      this.show_store_tour = false
      this.user_tour = new window.user_tour(base_tour_config);
      event_hub.$emit(SHOW_QUICK_LINKS)
      this.user_tour.setSteps(await (new_user_store_tour)(this.user_tour));
      this.user_tour.start();
    },
    toggle_notifications(close) {
      this.show_notifications = close ? false : !this.show_notifications
    },
    init_zendesk() {
      const zendesk = document.createElement("script");
      zendesk.id = "ze-snippet"
      zendesk.src = "https://static.zdassets.com/ekr/snippet.js?key=2e755430-5883-4b96-8ffa-8e6be30043c9"

      this.$refs["zendesk-wrapper"].appendChild(zendesk);
    },
    should_redirect_to_billing() {
      if (!this.subscription_payed) {
        this.$router.push(`/billing/${this.selected_project}/`)
      }
    },
    toggle_store_preview() {
      if (this.show_store_preview) {
        history.pushState("", "", window.location.href.replace(/\#.*/,''))
        window.localStorage.setItem(user_viewed_products, "[]")
        window.localStorage.setItem(user_basket_items, "[]")
      }

      this.show_store_preview = !this.show_store_preview
    },
    switch_selected_project() {
      this.admin_login({ user_id: this.user_info.id })
      this.select_user_project()
      this.clear_stores()
      this.$router.push(({ path: "/"  }))
    },
    show_guide() {
      this.open_user_menu = false
      this.show_store_tour = true
    },
    // Currently unused
    // init_tour(type) {
    //   this.update_loader(true)
    //   this.show_guide_modal = false
    //   this.preparing_guide = true
    //   this.$router.push({ name: `${type}_setup` })

    //   this.$nextTick(() => {
    //     this.preparing_guide = false
    //     this.update_loader(false)
    //   })
    // },
    update_user_language() {
      event_hub.$emit(update_admin_language, this.user_info.language_code || default_language)
    },
    update_favicon() {
      const link = document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = get_image_src(this.rest_config.favicon || this.rest_config.icon, image_dimension_names.mobile, true);

      document.head.appendChild(link);
    },
  }
}
</script>

<style lang="scss">
  @use "../../../styles/_global-constants" as *;
  @use "../../../styles/_admin-constants" as *;
  @use "../../../styles/admin" as *;

  .md-app {
    min-height: 100vh;
    width: 100%;
  }

  .md-right.md-drawer {
    bottom: 0 !important;
  }

  .md-tooltip {
    z-index: 1000 !important;

    @media (max-width: $tablet--small) {
      height: auto !important;
      white-space: normal !important;
      max-width: calc(100vw - #{$double-default-size});
      line-height: 25px !important;
      padding: 5px $half-default-size !important;
    }
  }

  .wt-popover {
    @media (max-width: $tablet) {
      max-width: calc(100vw - #{$admin-side-menu-width--mobile + $double-default-size});
    }
  }

  .admin {
    &__store-link {
      display: flex;
      align-items: center;
      justify-content: left;
      text-align: left;

      img {
        max-height: $icon-size;
        max-width: $icon-size--large;

        @media (max-width: $material-medium-breakpoint) {
          max-height: $icon-size--small;
          max-width: $icon-size;
        }
      }

      .md-title {
        cursor: pointer;
        white-space: normal !important;
        line-height: normal;

        @media (max-width: $material-medium-breakpoint) {
          font-size: $font-size--medium;
        }
      }

      b {
        text-overflow: ellipsis;
        white-space: nowrap;
        color: $blue;
      }
    }

    &__toolbar {
      position: fixed;
      display: grid;
      grid-template-columns: $icon-size calc(40% - #{$default-size}) 1fr;
      background-color: $material-grey--light !important;
      top: 0;
      left: 0;
      z-index: $z-index--middle;
      width: 100%;

      @media (max-width: $mobile--small) {
        grid-template-columns: $icon-size calc(35% - #{$default-size}) 1fr;
      }

      &-buttons {
        @media (max-width: $mobile) {
          .md-button {
            margin: 0;
            height: 35px;
            width: 35px;
            min-width: 35px;

            .md-ripple {
              padding: 0 5px;
            }
          }
        }
      }

      &-functions {
        display: grid;
        grid-template-columns: 1fr 1fr;
        flex: unset;
        order: unset;
        margin-left: auto;

        @media (max-width: $material-medium-breakpoint) {
          grid-template-columns: 1fr;
        }
      }

      @media (max-width: $material-medium-breakpoint) {
        min-height: $admin-top-bar-height--mobile;
        text-align: right;
      }
    }

    &__notifications {
      position: relative;

      &-count {
        position: absolute;
        background: $blue;
        font-size: 9px;
        right: -5px;
        top: -2px;
        border-radius: 100%;
        color: $pure-white;
        width: 17px;
        height: 17px;
        line-height: 17px;
        text-align: center;
      }
    }

    &__countdown {
      position: relative;
      margin-left: auto;
      margin-right: 5px;
      cursor: pointer;
      font-size: 10px;
      line-height: 12px;

      @media (max-width: $material-medium-breakpoint) {
        display: none;
      }

      &-button {
        display: none;

        @media (max-width: $material-medium-breakpoint) {
          display: inline-block;
        }
      }

      &-clock {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 5px;
        text-align: center;
        border-right: 1px solid $material-grey--darkest;
        padding: 5px $default-size 5px 0;

        &-days {
          display: none;
        }

        &-time {
          &-wrapper {
            position: relative;
            color: $black--light;
            font-size: 10px;

            b {
              display: block;
              width: 100%;
              margin-top: 2px;
              font-size: 14px;
              line-height: 14px;
            }
          }
        }
      }

      &-mobile-message {
        display: none;
        color: $black--light;
        font-weight: bold;
        margin: 0 $half-default-size 0 0;
        font-size: 10px;
        text-align: right;

        @media (max-width: $material-medium-breakpoint) {
          display: inline-block;
          vertical-align: sub;
        }
      }

      &-tooltip {
        @media (max-width: $material-medium-breakpoint) {
          display: none;
        }
      }

      &--warning {
        color: $material-red;
      }

      p {
        padding: $half-default-size;
        font-size: 15px;
        border-radius: 3px;
        color: $material-red;
        font-weight: bold;
      }
    }

    &__store-settings {
      position: absolute;
      bottom: $half-default-size;
      right: $half-default-size;
    }

    &__preview-changes-message {
      position: absolute;
      width: 100%;
      left: 0;
      top: calc(100vh - #{$admin-top-bar-height} - 25px);
      z-index: 6;
      padding: 3px $default-size;
      margin: 0;
      font-size: 12px;
      background-color: $material-orange;
      color: $pure-black;
      text-align: center;
      font-weight: bold;
    }

    &__user-menu {
      position: fixed;
      top: $admin-top-bar-height;
      right: 0;
      z-index: 10;

      @media (max-width: $material-medium-breakpoint) {
        top: $admin-top-bar-height--mobile;
      }

      &.md-active {
        max-width: $admin-side-menu-width;
        z-index: $z-index--top;
      }

      height: calc(100vh - #{$admin-top-bar-height});
      min-width: 200px !important;
    }

    & &--subscription-unpaid {
      .md-app-scroller {
        padding-left: 0 !important;
      }

      .md-toolbar {
        grid-template-columns: 0 calc(40% - 20px) 1fr !important;
      }
    }

    .md-app-content {
      height: auto;
      padding-top: 0;
    }

    .md-app-container {
      position: relative;
      overflow-x: hidden;
      -webkit-transform: unset;
      transform: unset;
    }

    .md-overlay {
      z-index: $z-index--bottom;
    }

    .md-content {
      position: relative;
      padding-bottom: 0;

      @media (max-width: $material-medium-breakpoint) {
        padding: 0 $half-default-size;
      }
    }

    .md-tabs .md-content {
      padding: 0;
    }

    &__modal-close-button {
      float: right;
      margin-top: -#{$half-default-size} !important;
    }

    &__log-out {
      margin-left: auto !important;
    }

    &__store-tour-buttons {
      display: flex;
      justify-content: space-between;
      margin: $double-default-size auto 0;
      max-width: 300px;

      .triple-arrow-button {
        width: 100%;
      }
    }

    &__user-message {
      margin: 0;
    }

    &__guide {
      &-modal {
        h1 {
          line-height: 28px;
        }

        &-close {
          position: absolute;
          right: 0;
          top: 6px;
        }
      }

      &-buttons {
        display: grid;
        margin-top: $double-default-size;
        grid-template-columns: 1fr 1fr;
        column-gap: $double-default-size;

        .md-button {
          height: 70px;
          font-size: 20px;
        }
      }
    }

    .md-app-scroller {
      padding: $admin-top-bar-height 0 0 $admin-top-bar-height !important;

      @media (max-width: $material-medium-breakpoint) {
        padding: $admin-top-bar-height--mobile 0 0 $admin-side-menu-width--mobile !important;
      }
    }

    & &--side-menu-open {
      .md-app-scroller {
        @media (max-width: $material-medium-breakpoint) {
          padding-left: 0 !important;
        }
      }

      .admin__toolbar {
        grid-template-columns: 0px 40% 1fr;

        @media (min-width: $material-mobile-breakpoint) {
          top: 0px;
          width: calc(100vw - #{$admin-side-menu-width});
          right: 0;
          left: auto;
        }
      }

      .admin__content {
        @media (min-width: $material-mobile-breakpoint) {
          margin-left: auto;
          width: calc(100vw - #{$admin-side-menu-width});
        }
      }
    }

    &__side-menu.md-drawer {
      position: fixed !important;
      z-index: 10;
      width: 20vw;
      max-width: 350px;
      bottom: 0;
      height: calc(100vh - $admin-top-bar-height);
      background-color: $material-grey--light !important;

      @media (max-width: $material-medium-breakpoint) {
        height: calc(100vh - $admin-side-menu-width--mobile);
        top: -#{$material-size / 2};

        &.md-persistent-mini:not(.md-active) {
          width: $admin-side-menu-width--mobile !important;

          .md-list-item-content {
            padding: 0 calc($admin-side-menu-width--mobile / 4);
          }
        }

        .md-list-item-content {
          padding: 0 calc($admin-side-menu-width--mobile / 4) !important;
        }
      }

      &.md-active {
        min-width: $admin-side-menu-width;
        width: $admin-side-menu-width;
        height: 100vh;
        top: 0;
        z-index: $z-index--top;
      }
    }

    @keyframes slide-store-preview {
      from {
        opacity: 0;
        transform: translateX(100%);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .store-preview-enter-active {
      animation: slide-store-preview .5s;
    }
    .store-preview-leave-active {
      animation: slide-store-preview .5s reverse;
    }
  }

  .notification-slide-enter-active, .notification-slide-leave-active {
    transition: .5s;
  }
  .notification-slide-enter, .notification-slide-leave-to {
    opacity: 0;
    transform: translateY(-#{$default-size});
  }

  #store-preview-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + #{$default-size});
    height: calc(100vh - #{$admin-top-bar-height});
    z-index: 5;
    background-color: var(--body_color);
    border-width: 0;
    border-style: none;
    border-color: unset;
    border-image: unset;
  }
</style>
